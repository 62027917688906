import { isEmpty, isNil } from "lodash";
import React from "react";
import { buildClassName, setImageParameters } from "../utils";
import Icon from "./icon";
import Image from "./image";
import Link from "./link";
import Pricing, { Price } from "./pricing";
import Rating from "./rating";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ProductCardProps {
  cardType?: "skyscraper" | "horizontal" | "landscape" | "carousel-highlight" | "hotel" | "modal";
  tag?: string;
  locationNames?: string[];
  title: string;
  description?: string;
  badges?: string[];
  rating?: number;
  price?: Price;
  imageSrc?: string;
  imageAlt?: string;
  linkUrl?: string;
  linkText?: string;
  hasFlight?: boolean;
  hasTransfer?: boolean;
  showAllIcons?: boolean;
  isExcursion?: boolean;
  isNotAvailable?: boolean;
  onRequest?: boolean;
  minStay?: string;
  isSearchResult?: boolean;
  useOriginalImage?: boolean;
  productType?: number;
  onClick?: () => void;
}

const getImageType = (
  cardType?: "skyscraper" | "horizontal" | "landscape" | "carousel-highlight" | "hotel" | "modal"
) => {
  switch (cardType) {
    case "horizontal":
      return undefined;
    case "skyscraper":
      return "9-16";
    case "landscape":
      return "carousel-highlight";
    case "hotel":
    default:
      return "golden-ratio";
  }
};

const ProductCard: React.FC<ProductCardProps> = ({
  cardType,
  tag,
  locationNames,
  title,
  description,
  badges,
  rating,
  price,
  imageSrc,
  imageAlt,
  linkUrl,
  linkText,
  hasFlight,
  hasTransfer,
  showAllIcons,
  isExcursion,
  isNotAvailable,
  onRequest,
  minStay,
  isSearchResult,
  useOriginalImage,
  onClick,
  children,
  productType,
}) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
  };
  const { t } = useI18next();

  return (
    <div className={buildClassName(["productcard", cardType && `productcard--${cardType}`])}>
      <div className="productcard__header">
        {imageSrc && (
          <Image
            href={linkUrl}
            type={getImageType(cardType)}
            title={linkText}
            src={
              useOriginalImage
                ? imageSrc
                : setImageParameters(imageSrc, {
                    mode: "max",
                    width: 768,
                  })
            }
            alt={imageAlt}
            onLinkClick={handleClick}
          >
            {tag && <span className="productcard__promo-label">{tag}</span>}
          </Image>
        )}
      </div>
      <div className="productcard__body">
        {locationNames && (
          <div className="destination-labels">
            {locationNames.map((locationName, index) => (
              <div className="destination-label" key={`${locationName}-${index}`}>
                <Icon name="ui-marker" />
                {locationName}
              </div>
            ))}
          </div>
        )}
        {badges && (
          <div className="white-labels">
            {badges.map((badge, index) => (
              <div className="white-label" key={`${index}-badge`}>
                {badge}
              </div>
            ))}
          </div>
        )}
        {rating && <Rating rating={rating} />}
        <h4 className="productcard__heading">
          <Link
            href={linkUrl}
            title={linkText}
            className="productcard__heading-link"
            onClick={handleClick}
          >
            {title}
          </Link>
        </h4>
        {description && (
          <div
            className="productcard__description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        )}
        {price && (
          <div className="productcard__icons">
            <div className="package-icons">
              {((!isExcursion && showAllIcons) || hasFlight) && (
                <div className="package-icons__icon">
                  <Icon name="ui-flight" title={t("FLIGHT_INCLUSIVE")} />
                </div>
              )}
              {((!isExcursion && showAllIcons) || hasTransfer) && (
                <div className="package-icons__icon">
                  <Icon name="ui-transfer" title={t("TRANSFER_INCLUDED")} />
                </div>
              )}
              {!isExcursion && !isNotAvailable && (
                <div className="package-icons__icon">
                  <Icon name="ui-bed" title={t("STAY_INCLUDED")} />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="productcard__summary">
          {price && !isNotAvailable && (!onRequest || (onRequest && productType != 1)) && (
            <Pricing {...price} isTotalPrice={isSearchResult} />
          )}
          {isNotAvailable && (
            <Pricing {...price} prefix={t("STARTING_FROM_SHORT")} suffix={t("PER_PERSON_SHORT")} />
          )}
          {(!isNil(linkUrl) || !isNil(onClick)) && !isEmpty(linkText) && (
            <Link
              href={linkUrl}
              title={linkText}
              className="cta cta--secondary"
              internal
              onClick={handleClick}
            >
              {linkText}
            </Link>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default ProductCard;
