import { BookingPackage, BookingPackageRoom } from "@qite/tide-client/build/types";
import { Maybe, TideItemForHotel, TideItemForRoundtrip } from "../../../types";
import { DateRange } from "../qsm/date-picker";
import { Room } from "../qsm/room-picker";
import { flightPair } from "../../../components/fly-in/fly-in-flights";

export interface QSMState {
  product?: TideItemForHotel | TideItemForRoundtrip;
  productId?: string;
  countryId?: number;
  regionId?: number;
  rooms: Room[];
  flight?: flightPair;
  dateRange: DateRange;
  bookingRooms?: BookingPackageRoom[];
  bookingPackage?: BookingPackage;
  loaded?: boolean;
  isLoading?: boolean;
  duration?: number;
}

const actionPrefix = "APP/QSM";

export const SET_PRODUCT = `${actionPrefix}/SET_PRODUCT`;
export const setProduct = (product?: TideItemForHotel) => ({
  type: SET_PRODUCT,
  product,
});

export const SET_PRODUCT_ID = `${actionPrefix}/SET_PRODUCT_ID`;
export const setProductId = (productId?: string) => ({
  type: SET_PRODUCT_ID,
  productId,
});

export const SET_COUNTRY_ID = `${actionPrefix}/SET_COUNTRY_ID`;
export const setCountryId = (countryId?: number) => ({
  type: SET_COUNTRY_ID,
  countryId,
});

export const SET_REGION_ID = `${actionPrefix}/SET_REGION_ID`;
export const setRegionId = (regionId?: number) => ({
  type: SET_REGION_ID,
  regionId,
});

export const SET_ROOMS = `${actionPrefix}/SET_ROOMS`;
export const setRooms = (rooms: Room[]) => ({
  type: SET_ROOMS,
  rooms,
});

export const SET_FLIGHT = `${actionPrefix}/SET_FLIGHT`;
export const setFlight = (flight?: flightPair) => ({
  type: SET_FLIGHT,
  flight,
});

export const SET_DATE_RANGE = `${actionPrefix}/SET_DATE_RANGE`;
export const setDateRange = (dateRange: DateRange) => ({
  type: SET_DATE_RANGE,
  dateRange,
});

export const SET_BOOKING_ROOMS = `${actionPrefix}/SET_BOOKING_ROOMS`;
export const setBookingRooms = (bookingRooms?: BookingPackageRoom[]) => ({
  type: SET_BOOKING_ROOMS,
  bookingRooms,
});

export const SET_BOOKING_PACKAGE = `${actionPrefix}/SET_BOOKING_PACKAGE`;
export const setBookingPackage = (bookingPackage?: BookingPackage) => ({
  type: SET_BOOKING_PACKAGE,
  bookingPackage,
});

export const SET_LOADED = `${actionPrefix}/SET_LOADED`;
export const setLoaded = (loaded?: boolean) => ({
  type: SET_LOADED,
  loaded,
});

export const SET_IS_LOADING = `${actionPrefix}/SET_IS_LOADING`;
export const setIsLoading = (isLoading?: boolean) => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const SET_DURATION = `${actionPrefix}/SET_DURATION`;
export const setDuration = (duration?: number) => ({
  type: SET_DURATION,
  duration,
});

export type Action =
  | ReturnType<typeof setProduct>
  | ReturnType<typeof setProductId>
  | ReturnType<typeof setCountryId>
  | ReturnType<typeof setRegionId>
  | ReturnType<typeof setRooms>
  | ReturnType<typeof setFlight>
  | ReturnType<typeof setDateRange>
  | ReturnType<typeof setBookingRooms>
  | ReturnType<typeof setBookingPackage>
  | ReturnType<typeof setLoaded>
  | ReturnType<typeof setIsLoading>
  | ReturnType<typeof setDuration>;

function getInitialRooms(): Room[] {
  return [
    {
      adults: 2,
      children: 0,
      childAges: [],
      isVisible: false,
    },
  ];
}

function getInitialDateRange(): DateRange {
  const now = new Date();

  const from = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 3, now.getDate()));
  const to = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate() + 7));

  return {
    fromDate: from,
    toDate: to,
  };
}

export const initialQSMState: QSMState = {
  countryId: undefined,
  regionId: undefined,
  rooms: getInitialRooms(),
  dateRange: getInitialDateRange(),
};

const runReducer = (state: QSMState, action: Action): QSMState => {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        product: (action as ReturnType<typeof setProduct>).product,
      };
    case SET_PRODUCT_ID:
      return {
        ...state,
        productId: (action as ReturnType<typeof setProductId>).productId,
      };
    case SET_COUNTRY_ID:
      return {
        ...state,
        countryId: (action as ReturnType<typeof setCountryId>).countryId,
        regionId: undefined,
      };
    case SET_REGION_ID:
      return {
        ...state,
        regionId: (action as ReturnType<typeof setRegionId>).regionId,
        countryId: undefined,
      };
    case SET_ROOMS:
      return { ...state, rooms: (action as ReturnType<typeof setRooms>).rooms };
    case SET_FLIGHT:
      return { ...state, flight: (action as ReturnType<typeof setFlight>).flight };
    case SET_DATE_RANGE:
      return { ...state, dateRange: (action as ReturnType<typeof setDateRange>).dateRange };
    case SET_BOOKING_ROOMS:
      return {
        ...state,
        bookingRooms: (action as ReturnType<typeof setBookingRooms>).bookingRooms,
      };
    case SET_BOOKING_PACKAGE:
      return {
        ...state,
        bookingPackage: (action as ReturnType<typeof setBookingPackage>).bookingPackage,
      };
    case SET_LOADED:
      return {
        ...state,
        loaded: (action as ReturnType<typeof setLoaded>).loaded,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: (action as ReturnType<typeof setIsLoading>).isLoading,
      };
    case SET_DURATION:
      return {
        ...state,
        duration: (action as ReturnType<typeof setDuration>).duration,
      };
    default:
      return state;
  }
};

export const qsmReducer = (state: QSMState = initialQSMState, action: Action): QSMState => {
  const newState = runReducer(state, action);

  /*
  console.groupCollapsed(action.type);
  console.info("action:", action);
  console.info("new state:", newState);
  console.info("old state:", state);
  console.groupEnd();
  */

  return newState;
};
