import React from "react";
import { buildClassName } from "../utils";

interface CopyProps {
  title: string;
  description?: string;
  centered?: boolean;
}

const Copy: React.FC<CopyProps> = ({ title, description, centered }) => {
  return (
    <div className={buildClassName(["copy", centered && "copy--centered"])}>
      <h2 className="copy__heading">{title}</h2>
      {description && (
        <div className="copy__description" dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  );
};

export default Copy;
