import React from "react";
import { compact, isEmpty } from "lodash";
import { buildClassName, setImageParameters } from "../utils";

interface HeroProps {
  type?: "qsm" | "product" | "compact";
  imageSrc?: string;
  title?: string;
  titlePrefix?: string;
}

const Hero: React.FC<HeroProps> = ({ type, imageSrc, title, titlePrefix, children }) => (
  <section className={buildClassName(["hero", type && `hero--${type}`])}>
    {imageSrc && (
      <img
        src={setImageParameters(imageSrc, { width: 1920, mode: "max" })}
        alt={compact([titlePrefix, title]).join(" ")}
        className="hero__media"
      />
    )}
    <div className="hero__content">
      <h1>
        {!isEmpty(titlePrefix) && <em>{titlePrefix}</em>}
        {title}
      </h1>
      {children}
    </div>
  </section>
);

export default Hero;
