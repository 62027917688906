import React from "react";
import { buildClassName } from "../utils";

interface ProductCardGridProps {
  columns?: number;
  itemsComponent?: React.ReactElement;
  actionsComponent?: React.ReactElement;
}

const ProductCardGrid: React.FC<ProductCardGridProps> = ({
  columns,
  itemsComponent,
  actionsComponent,
}) => {
  const className = buildClassName(["productcard-grid", columns && `productcard-grid--${columns}`]);
  return (
    <div className={className}>
      {itemsComponent && <div className="productcard-grid__items">{itemsComponent}</div>}
      {actionsComponent && <div className="productcard-grid__actions">{actionsComponent}</div>}
    </div>
  );
};

export default ProductCardGrid;
