import React from "react";
import { buildClassName } from "../utils";

interface SectionProps {
  id?: string;
  extraClassName?: string;
}

const Section: React.FC<SectionProps> = (props) => {
  return (
    <section
      id={props.id ?? undefined}
      className={buildClassName(["section", props.extraClassName])}
    >
      {props.children}
    </section>
  );
};

export default Section;
