import React from "react";
import { clamp, range } from "lodash";
import Icon from "./icon";

interface RatingProps {
  rating: number;
}

const Rating: React.FC<RatingProps> = ({ rating }) => {
  const clampedRating = clamp(rating, 0, 5);
  return (
    <div className="rating">
      {range(0, Math.floor(clampedRating)).map((i) => (
        <Icon name="product-star" key={`rating-star-${i}`} />
      ))}
      {clampedRating % 1 > 0 && <Icon name="product-halfstar" />}
    </div>
  );
};

export default Rating;
