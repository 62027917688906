import { sumBy } from "lodash";
import React from "react";
import { useQSMStore } from "../../app/search-results/qsm-store/context";
import { formatter } from "../../utils";
import { useI18next } from "gatsby-plugin-react-i18next";

export interface Price {
  defaultPrice?: number;
  promoPrice?: number;
}

interface PricingProps extends Price {
  isTotalPrice?: boolean;
  prefix?: string;
  suffix?: string;
}

const mapNaNToUndefined = (price?: number) => (price && !isNaN(price) ? price : undefined);

const Pricing: React.FC<PricingProps> = ({
  isTotalPrice,
  defaultPrice,
  promoPrice,
  prefix,
  suffix,
}) => {
  const { t } = useI18next();
  prefix = prefix ?? t("STARTING_FROM_SHORT");
  suffix = suffix ?? t("PER_PERSON_SHORT");
  const [qsmState] = useQSMStore();
  const formerPrice = mapNaNToUndefined(promoPrice && defaultPrice ? defaultPrice : undefined);
  const currentPrice = mapNaNToUndefined(promoPrice ?? defaultPrice);

  const getFlightPriceDifference = () => {
    if (qsmState?.bookingPackage && qsmState.flight) {
      let originalFlightsPrice = 0;
      let desiredFlightsPrice = 0;

      originalFlightsPrice +=
        qsmState?.bookingPackage.outwardFlights.find((x) => x.isSelected)?.price ?? 0;
      originalFlightsPrice +=
        qsmState?.bookingPackage.returnFlights.find((x) => x.isSelected)?.price ?? 0;

      desiredFlightsPrice += qsmState.flight.outward.price ?? 0;
      desiredFlightsPrice += qsmState.flight.return.price ?? 0;

      if (desiredFlightsPrice && originalFlightsPrice) {
        return desiredFlightsPrice - originalFlightsPrice;
      }
    }
    return 0;
  };

  const getRoomPriceDifference = () => {
    const activeOption = qsmState?.bookingPackage?.options.find((x) => x.isSelected);
    if (activeOption?.rooms && qsmState?.rooms) {
      let originalOptionsPrice = 0;
      let desiredOptionsPrice = 0;
      for (let index = 0; index < qsmState?.rooms.length; index++) {
        const room = activeOption.rooms[index];

        const originalOption = room?.options?.find((x) => x.isSelected);
        originalOptionsPrice += originalOption?.price ?? 0;

        const desiredOption = room?.options?.find(
          (x) =>
            x.accommodationCode == qsmState?.rooms[index].accommodationCode &&
            x.regimeCode == qsmState?.rooms[index].regimeCode
        );
        desiredOptionsPrice += desiredOption ? desiredOption.price : originalOption?.price ?? 0;
      }
      if (desiredOptionsPrice && originalOptionsPrice) {
        return desiredOptionsPrice - originalOptionsPrice;
      }
    }
    return 0;
  };

  const getTotalPriceDifference = () => {
    const flightPriceDifference = getFlightPriceDifference();
    const roomPriceDifference = getRoomPriceDifference();

    return flightPriceDifference + roomPriceDifference;
  };

  const getTotalAmountOfAdults = () => {
    return sumBy(qsmState?.rooms, "adults");
  };

  const getTotalAmountOfChildren = () => {
    return sumBy(qsmState?.rooms, "children");
  };

  return currentPrice ? (
    <div className="pricing">
      <div className="pricing__header">
        {/* {isTotalPrice && (
          <span className="pricing__label pricing__label--full-width">
            {translations.SEARCH_RESULTS.PRODUCT_CARD.TOTAL_PRICE}
          </span>
        )} */}
        {!isTotalPrice && <span className="pricing__label">{prefix}</span>}
        {formerPrice && (
          <div className="pricing__former-price">{formatter.format(formerPrice)}</div>
        )}
        <div className="pricing__break"></div>
        <div className="pricing__price">
          {formatter.format(currentPrice + getTotalPriceDifference())}
        </div>
        {!isTotalPrice && <span className="pricing__label">{suffix}</span>}
      </div>
      {isTotalPrice && (
        <div className="pricing__footer">
          {getTotalAmountOfChildren() > 0
            ? t("PRICE_FOR_X_ADULTS_AND_Y_CHILDREN", {
                numberOfAdults: getTotalAmountOfAdults(),
                numberOfChildren: getTotalAmountOfChildren(),
              })
            : t("PRICE_FOR_X_ADULTS", { numberOfAdults: getTotalAmountOfAdults() })}
        </div>
      )}
    </div>
  ) : null;
};

export default Pricing;
