import React from "react";
import { buildClassName } from "../utils";
import Link from "./link";

interface ImageProps {
  type?: "golden-ratio" | "16-9" | "9-16" | "carousel-highlight";
  count?: number;
  href?: string;
  title?: string;
  src?: string;
  alt?: string;
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const Image: React.FC<ImageProps> = ({
  type,
  count,
  href,
  title,
  src,
  alt,
  onLinkClick,
  children,
}) => (
  <div className={buildClassName(["image", type && `image--${type}`])}>
    <Link
      href={href}
      internal
      title={title}
      className="image__link"
      data-count={count && `+${count}`}
      onClick={onLinkClick}
    >
      <img src={src} alt={alt} className="image__content" />
      {children}
    </Link>
  </div>
);

export default Image;
