import React from "react";

interface IntroProps {
  title?: string;
}

const Intro: React.FC<IntroProps> = ({ title, children }) => (
  <div className="intro">
    <div className="intro__title">
      <h2>{title}</h2>
    </div>
    <div className="intro__copy">{children}</div>
  </div>
);

export default Intro;
