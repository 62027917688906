import React, { useEffect, useContext, useReducer } from "react";
import { useLocation } from "@reach/router";
import { getDateFromParams, getNumberFromParams, getRoomsFromParams } from "../qsm/utils";
import {
  Action,
  setCountryId,
  setDateRange,
  setRegionId,
  setRooms,
  QSMState,
  setLoaded,
} from "./reducer";

interface ProviderProps {
  initialState: QSMState;
  duration?: number | null;
  reducer: (state: QSMState, action: Action) => QSMState;
}

const Store = React.createContext<Partial<[QSMState, React.Dispatch<Action>]>>([]);
Store.displayName = "QSMStore";

export const useQSMStore = () => useContext(Store)!;

export const QSMStoreProvider: React.FC<ProviderProps> = ({ children, initialState, reducer }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const country = getNumberFromParams(params, "country");
    const region = getNumberFromParams(params, "region");
    const rooms = getRoomsFromParams(params, "rooms");
    const from = getDateFromParams(params, "from");
    const to = getDateFromParams(params, "to");

    if (country) {
      dispatch(setCountryId(country));
    }

    if (region) {
      dispatch(setRegionId(region));
    }

    if (rooms) {
      dispatch(setRooms(rooms));
    }

    if (from && state.duration) {
      const durationTo = new Date(
        Date.UTC(from.getFullYear(), from.getMonth(), from.getDate() + state.duration)
      );

      dispatch(setDateRange({ fromDate: from, toDate: durationTo }));
    } else if (from && to) {
      dispatch(setDateRange({ fromDate: from, toDate: to }));
    } else if (state.duration) {
      const now = new Date();

      const durationFrom = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 3, now.getDate()));
      const durationTo = new Date(
        Date.UTC(
          durationFrom.getFullYear(),
          durationFrom.getMonth(),
          durationFrom.getDate() + state.duration
        )
      );

      dispatch(setDateRange({ fromDate: durationFrom, toDate: durationTo }));
    }

    dispatch(setLoaded(true));
  }, [location]);

  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};
