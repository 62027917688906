import React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
  title?: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
  language?: string;
}

const Seo: React.FC<SEOProps> = ({ title, seoTitle, seoDescription, seoKeywords, language }) => {
  return (
    <Helmet
      title={seoTitle ?? title}
      htmlAttributes={{
        lang: language?.substring(0, 2),
      }}
      meta={[
        {
          name: `description`,
          content: seoDescription,
        },
        {
          name: `keywords`,
          content: seoKeywords,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        {
          property: `og:description`,
          content: seoDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: ``,
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: seoDescription,
        },
      ].concat()}
    />
  );
};

export default Seo;
